import Head from "next/head";
import React from "react";
import { Deployment, foldDeployment_ } from "../domain/deployment";
import { I18nContext, useI18n } from "../domain/i18n";
import { PageMeta } from "../domain/page";

export const PageInfo = (props: PageMeta & { deployment: Deployment }) => {
  const I18n = useI18n();

  const baseUrl = foldDeployment_(props.deployment, {
    ch: () => "https://gemeinwohl.ch",
    de: () => "https://gemeinwohlatlas.de",
  });

  return (
    <Head>
      <title>{buildTitle(I18n, props.title, props.subtitle)}</title>
      {props.meta_description && <meta name="description" content={props.meta_description} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={I18n.t("meta.twitter.handle")} />
      <meta name="twitter:creator" content={I18n.t("meta.twitter.handle")} />

      <meta property="og:url" content={`${baseUrl}`} />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={`${baseUrl}/images/share_${props.deployment}_${I18n.locale}.jpg`} />
      <meta property="og:title" content={I18n.t("meta.opengraph.title")} />
      <meta property="og:description" content={I18n.t("meta.opengraph.description")} />
    </Head>
  );
};

// -----------------------------------------------------------------------------

function buildTitle(I18n: I18nContext, pageTitle?: string, subtitle?: string) {
  return [pageTitle, subtitle, I18n.t("meta.title")].filter(Boolean).join(" | ");
}
